var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        dialogClass: "translate-modal",
        "on-ok": "handleOk",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v("佳句")]
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
            on: { mouseover: _vm.topCenterMouseover },
          },
          [
            _c("span"),
            _c(
              "span",
              [
                _c(
                  "a-button",
                  { key: "back", on: { click: _vm.handleCancel } },
                  [_vm._v("\n          取消\n        ")]
                ),
                _c(
                  "a-button",
                  {
                    key: "submit",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleOk },
                  },
                  [_vm._v("\n          确定\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "作品名称", prop: "productionId" } },
            [
              _c("a-tree-select", {
                ref: "treeSelect",
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: true,
                  "show-search": "",
                  dropdownClassName: "sentence-tree",
                  filterTreeNode: false,
                  "tree-data-simple-mode": "",
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.treeData,
                  placeholder: "请选择",
                  "load-data": _vm.onLoadData,
                },
                on: { select: _vm.handleNodeClick, search: _vm.search },
                model: {
                  value: _vm.formData.productionId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "productionId", $$v)
                  },
                  expression: "formData.productionId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "章节序号", prop: "chapterId" } },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.chapterLoading } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: true,
                        placeholder: "请选择",
                        "show-search": "",
                      },
                      model: {
                        value: _vm.formData.chapterId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "chapterId", $$v)
                        },
                        expression: "formData.chapterId",
                      },
                    },
                    [
                      _vm._l(_vm.chapterList, function (chapter) {
                        return _c(
                          "a-select-option",
                          { key: chapter.id, attrs: { value: chapter.id } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(chapter.sequenceNo) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      _vm.isEdit &&
                      _vm.chapterList.every(
                        (chapter) => chapter.id != _vm.formData.chapterId
                      ) &&
                      _vm.formData.chapterId
                        ? _c(
                            "a-select-option",
                            { attrs: { value: _vm.formData.chapterId } },
                            [_vm._v("\n            章节已删除\n          ")]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "原文", prop: "originalText" } },
            [
              _c("a-textarea", {
                attrs: {
                  "auto-size": { minRows: 1, maxRows: 10 },
                  placeholder: "请输入原文",
                },
                model: {
                  value: _vm.formData.originalText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "originalText", $$v)
                  },
                  expression: "formData.originalText",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "译文", prop: "translateText" } },
            [
              _c("a-textarea", {
                attrs: {
                  "auto-size": { minRows: 1, maxRows: 10 },
                  disabled: true,
                  placeholder: "请输入译文",
                },
                model: {
                  value: _vm.formData.translateText,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "translateText", $$v)
                  },
                  expression: "formData.translateText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }