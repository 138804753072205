<template>
  <a-modal :maskClosable="false" dialogClass="translate-modal" v-model="visible" on-ok="handleOk">
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <div @mouseover="topCenterMouseover" slot="title" class="modal-title">佳句</div>
    <template slot="footer">
      <div @mouseover="topCenterMouseover" style="display:flex;justify-content: space-between;">
        <span><!-- <a @click="addAgin">再次新建</a> --></span>
        <span>
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="handleOk">
            确定
          </a-button>
        </span>
      </div>
    </template>
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="作品名称" prop="productionId">
        <a-tree-select
          ref="treeSelect"
          :disabled="true"
          v-model="formData.productionId"
          show-search
          @select="handleNodeClick"
          dropdownClassName="sentence-tree"
          :filterTreeNode="false"
          @search="search"
          tree-data-simple-mode
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          placeholder="请选择"
          :load-data="onLoadData"
        />
      </a-form-model-item>
      <a-form-model-item label="章节序号" prop="chapterId">
        <a-spin :spinning="chapterLoading">
          <a-select :disabled="true" placeholder="请选择" v-model="formData.chapterId" show-search style="width: 100%">
            <a-select-option :value="chapter.id" v-for="chapter in chapterList" :key="chapter.id">
              {{ chapter.sequenceNo }}
            </a-select-option>
            <a-select-option
              v-if="isEdit && chapterList.every(chapter => chapter.id != formData.chapterId) && formData.chapterId"
              :value="formData.chapterId"
            >
              章节已删除
            </a-select-option>
          </a-select>
        </a-spin>
      </a-form-model-item>
      <a-form-model-item label="原文" prop="originalText">
        <a-textarea :auto-size="{ minRows: 1, maxRows: 10 }" v-model="formData.originalText" placeholder="请输入原文" />
      </a-form-model-item>
      <a-form-model-item label="译文" prop="translateText">
        <a-textarea
          :auto-size="{ minRows: 1, maxRows: 10 }"
          :disabled="true"
          v-model="formData.translateText"
          placeholder="请输入译文"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '../../../api/manage'
import { USER_INFO } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  props: {
    taskDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      formData: { productionId: null, chapterId: undefined, originalText: '', translateText: '' },
      paarms: { productName: '', platformIds: [], coordinatorIds: [] },
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      treeData: [],
      rules: {
        productionId: [{ required: true, message: '请选择作品', trigger: 'change' }],
        chapterId: [{ required: true, message: '请选择章节序号', trigger: 'change' }],
        originalText: [{ required: true, message: '请输入原文', trigger: 'blur' }],
        translateText: [{ required: true, message: '请输入译文', trigger: 'blur' }]
      },
      list: [],
      chapterLoading: false,
      chapterList: [],
      productionName: '',
      productionId: null,
      userInfo: null,
      record: null
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    addAgin() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = await postAction('/beautiful/sentence/edit', {
            avatar: this.record.avatar,
            chapterId: this.formData.chapterId,
            originalText: this.formData.originalText,
            productionId: this.productionId,
            productionName: this.productionName,
            translateText: this.formData.translateText,
            createStage: this.taskDetail.stageName
          })
          if (res.code == 200) {
            this.$message.success('添加成功')
            this.formData.originalText = this.record.originalText
            this.formData.translateText = this.record.translateText
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    async getPlatFrom() {
      this.treeData = []
      const res = await postAction('sys/sysDepart/getDepartTreeWithPlatform', this.paarms)
      let list = res.data || []
      list.forEach((item, index) => {
        item.isDepartment = true
        item.index = index
        item.key = item.id
        item.checked = false
        item.showName = item.parentName || ''

        item.childrenList = item.platformList?.map((child, c_index) => {
          child.isPlatform = true
          child.checked = false
          child.index = c_index
          // 平台ID和作品ID可能会重复，影响表格渲染性能
          // 因此给平台重新定义key（平台相对较少
          child.key = `platform_${child.platformId}`
          child.hasChild = child.productTotal > 0
          child.showName = child.platformName || ''
          child.showCount = child.productTotal || 0
          child.childrenList = child.productionList
            ? child.productionList.map(p => {
                return {
                  id: p.id,
                  showName: p.productionName,
                  pId: child.platformId
                }
              })
            : []

          return child
        })
        // 避免原有列表占用内存
        item.platformList = undefined
      })
      this.list = list
      if (!this.paarms.productName) {
        this.treeData = this.list.map(item => {
          return { id: item.id, pId: 0, value: item.id, title: item.showName, disabled: true }
        })
      } else {
        this.treeData = this.list.map(item => {
          return {
            id: item.id,
            pId: 0,
            value: item.id,
            title: item.showName,
            disabled: true,
            children: item.childrenList.map(c => {
              return {
                id: c.platformId,
                pId: item.id,
                value: c.platformId,
                title: c.showName,
                disabled: true,
                children: c.childrenList.map(child => {
                  return {
                    id: child.id,
                    pId: child.pId,
                    value: child.id,
                    title: child.showName
                  }
                })
              }
            })
          }
        })
      }
    },
    open() {
      this.visible = true
      this.isEdit = false
      this.getPlatFrom()
    },
    edit(record) {
      this.getPlatFrom()
      this.getChapter(record.productionId)
      this.visible = true
      this.isEdit = true
      this.record = record
      this.productionName = record.productionName
      this.productionId = record.productionId
      this.formData.chapterId = record.chapterId
      this.formData.productionId = this.productionName
      this.formData.originalText = record.originalText
      this.formData.translateText = record.translateText
    },
    async getChapter(id) {
      this.chapterLoading = true
      const res = await getAction('/production/list_chapter/byProductionId', {
        current: 1,
        pageSize: -1,
        id
      })
      this.chapterLoading = false
      if (res.code == 200) {
        this.chapterList = res.data.records
      } else {
        this.$message.error(res.msg)
      }
    },
    handleNodeClick(value, node) {
      this.productionName = node.title
      this.productionId = value
      this.getChapter(value)
      this.$nextTick(() => {
        this.formData.productionId = this.productionName
      })
    },
    innitForm() {
      this.formData = { productionId: null, chapterId: undefined, originalText: '', translateText: '' }
      this.productionId = null
      this.productionName = ''
      this.visible = false
      this.record = null
    },
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (!this.formData.originalText.replaceAll('\n', '').replaceAll(' ', '').length) {
            this.$message.error('原文无字符')
            return
          }
          if (!this.formData.translateText.replaceAll('\n', '').replaceAll(' ', '').length) {
            this.$message.error('译文无字符')
            return
          }
          if (this.chapterList.every(chapter => chapter.id != this.formData.chapterId)) {
            this.$message.error('章节已删除')
            return
          }
          if (!this.isEdit) {
            const res = await postAction('/beautiful/sentence/edit', {
              avatar: this.userInfo.avatar,
              chapterId: this.formData.chapterId,
              originalText: this.formData.originalText,
              productionId: this.productionId,
              productionName: this.productionName,
              translateText: this.formData.translateText,
              createStage: this.taskDetail.stageName
            })
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.innitForm()
            } else {
              this.$message.error(res.msg)
            }
          } else {
            const res = await postAction('/beautiful/sentence/edit', {
              avatar: this.userInfo.avatar,
              chapterId: this.record.chapterId,
              productionId: this.record.productionId,
              productionName: this.record.productionName,
              createStage: this.taskDetail.stageName,
              originalText: this.formData.originalText,
              translateText: this.formData.translateText,
              userId: this.userInfo.id,
              isPass: 0,
              username: this.userInfo.nickName
            })
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.innitForm()
            } else {
              this.$message.error(res.msg)
            }
          }
        }
      })
    },
    handleCancel() {
      this.innitForm()
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        const { id } = treeNode.dataRef
        if (this.list.some(item => item.id == id)) {
          this.treeData = this.treeData.concat(this.genTreeNode(id))
          resolve()
        } else {
          let arr = []
          postAction('/production/queryProduct', {
            platformIds: [id],
            productionPage: {
              size: -1,
              current: 1
            }
          }).then(res => {
            if (res.code == 200) {
              arr = res.data.records.map(item => {
                return {
                  id: item.id,
                  pId: id,
                  value: item.id,
                  title: item.productionName,
                  isLeaf: true
                }
              })
              this.treeData = this.treeData.concat(arr)
            } else {
              this.$message.error(res.msg)
            }
            resolve()
          })
        }
      })
    },
    search(e) {
      this.paarms.productName = e
      this.getPlatFrom()
    },
    genTreeNode(parentId) {
      let arr = this.list
        .find(item => item.id == parentId)
        .childrenList.map(item => {
          return {
            id: item.id,
            pId: parentId,
            disabled: true,
            value: item.id,
            title: item.showName,
            isLeaf: false
          }
        })
      return arr
    }
  }
}
</script>

<style scoped lang="less">
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
</style>
